<template>
  <div class="setting-item s-search-type">
    <esmp-radio-group
      v-model="localSetting.value"
      :items="localSetting.options.map((el) => ({ value: el.name, label: el.title }))"
      class="s-search-type__radio-group"
    />
    <div class="s-search-type__childs">
      <div
        v-for="child in selectedOption.childs"
        :key="child.id"
        class="s-search-type__child"
      >
        <esmp-switch
          v-if="child.dataType === 'boolean'"
          :key="child.id"
          class="s-switch"
          v-model="child.value"
          shape="circular"
          :label="child.name"
        />
        <div
          v-if="child.techName === 'customEmployeeSearchListEnabled' && child.value"
          class="s-search-type__custom-search-list-wrapper"
        >
          <esmp-button size="small" @click="searchListShowed = true">
            Настроить список
          </esmp-button>
          <esmp-modal
            v-model="searchListShowed"
            title="Настройка списка"
            width="800"
          >
            <custom-employee-search-list :users-object="child.childs[0]" />
            <template #footer>
              <esmp-button @click="searchListShowed = false">
                Закрыть
              </esmp-button>
            </template>
          </esmp-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomEmployeeSearchList from './CustomEmployeeSearchList.vue';

export default {
  name: 'SSearchType',
  components: { CustomEmployeeSearchList },
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchListShowed: false,
    };
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    selectedOption() {
      const selected = this.localSetting?.options?.find((item) => item.name === this.localSetting.value);
      return selected || [];
    },
  },
};
</script>
<style lang="scss">
.s-search-type {
  &__radio-group, &__childs, &__child {
    margin-bottom: $base-gutter / 1.5;
  }
  &__child {
    display: flex;
    align-items: center;
    gap: $base-gutter;
  }
}
</style>
